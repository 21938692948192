import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { ApiEndpoints } from "../ApiEndpoints";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ImCross } from "react-icons/im";
import { ColorRing } from "react-loader-spinner";
import Pagination from "@mui/material/Pagination";

function checkManager(cookieData) {
  const parseData = JSON.parse(cookieData);
  if (parseData?.data?.Roles?.includes("Sales Manager")) {
    return true;
  } else {
    return false;
  }
}

const Leads = () => {
  const cookieData = Cookies.get("userLoginData");
  const navigate = useNavigate();
  
  useEffect(() => {
    if (cookieData !== "undefined") {
      const parseData = JSON.parse(cookieData);
      if (
        parseData?.data?.Roles?.includes("Sales Manager") ||
        parseData?.data?.Roles?.includes("Salesrep")
      ) {
        const res = checkManager(cookieData);
        fetchLeadData(res);
      } else {
        navigate("/dashboard");
      }
    } else {
      navigate("/");
    }
  }, [cookieData]);

  let data;
  if (cookieData !== undefined) {
    data = JSON.parse(cookieData);
  }
  
  const [searchLoading, setSearchLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const toggleMenu = (Id, Doctor__c) => {
    navigate("/visit-form", {
      state: {
        leadId: Id,
        Doctor__c: Doctor__c,
        createdWith: "Lead",
      },
    });
  };

  const fetchLeadData = async (isManager) => {
    setLoading(true);
    try {
      const cookieData = Cookies.get("userLoginData");
      const parsedData = JSON.parse(cookieData);
      const sfUserId = parsedData.data.sf_user_id;
      let url = "";
      if (isManager) {
        url = `${ApiEndpoints.LEADS}`;
      } else {
        url = `${ApiEndpoints.LEADS}?user_id=${sfUserId}`;
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${data.access_token}`,
        },
      });
      setTableData(response.data.message.records);
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.detail === "token_expired") {
        Cookies.remove("userLoginData");
        navigate("/");
      }
      setLoading(false);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = tableData?.slice(indexOfFirstRow, indexOfLastRow);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  let parsedData = null;
  if (cookieData !== undefined) {
    parsedData = JSON.parse(cookieData);
  }
  
  const sfUserId = parsedData && parsedData ? parsedData.data.sf_user_id : null;

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (search.length > 2) {
        setSearchLoading(true);
        try {
          let url = "";
          const res = checkManager(cookieData);
          if (res) {
            url = `${ApiEndpoints.LEADS}?search_term=${search}`;
          } else {
            url = `${ApiEndpoints.LEADS}?user_id=${sfUserId}&search_term=${search}`;
          }
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${data.access_token}`,
            },
          });

          setTableData(response?.data?.message?.records);
          setCurrentPage(1);
          setSearchLoading(false);
        } catch (error) {
          if (error?.response?.data?.detail === "token_expired") {
            Cookies.remove("userLoginData");
            navigate("/");
          }
          setSearchLoading(false);
        }
      }

      if (search.length == 0) {
        setSearchLoading(true);
        try {
          let url = "";
          const res = checkManager(cookieData);
          if (res) {
            url = `${ApiEndpoints.LEADS}`;
          } else {
            url = `${ApiEndpoints.LEADS}?user_id=${sfUserId}`;
          }
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${data.access_token}`,
            },
          });

          setTableData(response?.data?.message?.records);
          setSearchLoading(false);
        } catch (error) {
          if (error?.response?.data?.detail === "token_expired") {
            Cookies.remove("userLoginData");
            navigate("/");
          }
          setSearchLoading(false);
        }
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [search]);

  const [PagTrue, setPageTrue] = useState(false);

  setTimeout(() => {
    setPageTrue(true);
  }, 3000);
  
  return (
    <>
      <ToastContainer />
      {loading ? (
        <div className="flex justify-center mt-10 align-middle">
          <div role="status">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        </div>
      ) : (
        <div className="">
          <div className="flex flex-row justify-end gap-2 pr-3  w-[80%] m-auto">
            <input
              type="text"
              placeholder="Search Name..."
              value={search}
              className="mt-5 py-2  h-[40px] px-3 border border-[gray] focus:outline-none rounded-md  max-w-[300px] lg:w-[100%] me-7"
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value.length == 0) {
                  fetchLeadData();
                }
              }}
            />
            {search.length > 0 && (
              <ImCross
                className="relative px-1 py-1 text-sm text-white bg-gray-700 rounded-lg cursor-pointer top-[2rem] right-[4rem]"
                onClick={() => {
                  setSearch("");
                }}
              />
            )}
          </div>
          <div className="relative mx-4 mt-10 overflow-x-auto action-table lg:mx-14">
            {searchLoading ? (
              <div className="flex justify-center mt-20 ">
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={[
                    "#e15b64",
                    "#f47e60",
                    "#f8b26a",
                    "#abbd81",
                    "#849b87",
                  ]}
                />
              </div>
            ) : tableData?.length == 0 ? (
              <>
                <div className="m-auto max-w-[900px] h-[200px] bg-[#F4F7FB] flex place-content-center items-center border border-[#d2d2d2] rounded-md mt-6">
                  <p>No Result Found</p>
                </div>
              </>
            ) : (
              <table className="w-[80%]  m-auto text-sm text-left rtl:text-right text-[#2A1E17]  border-collapse: separate mt-[40px]">
                <thead className="text-xs text-[#2A1E17] uppercase ">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-4  bg-[#F4F7FB] border-t border-l"
                    ></th>
                    <th
                      scope="col"
                      className="px-6 py-4  bg-[#F4F7FB] rounded-tl-lg border-t border-[#D9D9D9]"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4  bg-[#F4F7FB] border-t"
                    >
                      Company
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4  bg-[#F4F7FB] border-t"
                    >
                      Street
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4  bg-[#F4F7FB] border-t"
                    >
                      City
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4  bg-[#F4F7FB]  border-t border-[#D9D9D9]"
                    >
                      State
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4  bg-[#F4F7FB] border-[#D9D9D9] border-t border-r"
                    >
                      Doctor
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentRows &&
                    currentRows.length > 0 &&
                    currentRows.map((res) => {
                      return (
                        <tr className="bg-white" key={res.Id}>
                          <td className="px-6 py-4 border-[#D9D9D9] border-b border-l">
                            {" "}
                            <button
                              className="bg-[#474747] text-[white] flex gap px-4 py-[10px] font-semibold rounded-md"
                              onClick={() => {
                                toggleMenu(res.Id, res?.Doctor__c);
                              }}
                            >
                              Log a Visit
                            </button>
                          </td>
                          <th
                            scope="row"
                            className="px-6 py-4  text-[#9B9DA0] font-semibold whitespace-nowrap rounded-lg border-b border-[#D9D9D9]"
                          >
                            {res.Name ? res.Name : "N/A"}
                          </th>
                          <td className="px-6 py-4 border-b border-[#D9D9D9] text-[#9B9DA0] font-semibold">
                            {res.Company ? res.Company : "N/A"}
                          </td>
                          <td className="px-6 py-4 border-b border-[#D9D9D9] text-[#9B9DA0] font-semibold">
                            {res.Street ? res.Street : "N/A"}
                          </td>
                          <td className="px-6 py-4 border-b border-[#D9D9D9] text-[#9B9DA0] font-semibold">
                            {res.City ? res.City : "N/A"}
                          </td>
                          <td className="px-6 py-4 border-b  border-[#D9D9D9] text-[#9B9DA0] font-semibold">
                            {res.State ? res.State : "N/A"}
                          </td>
                          <td className="px-6 py-4 border-b border-[#D9D9D9] border-r text-[#9B9DA0] font-semibold">
                            {res.Doctor__c ? res.Doctor__c : "N/A"}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
            {tableData && PagTrue && tableData.length > 7 && (
              <div className="flex justify-end my-3 w-[80%] m-auto py-3">
                <Pagination
                  count={Math.ceil(tableData.length / rowsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Leads;
