import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { Cookies } from "react-cookie";

function PrivateComponent() {
  const cookie = new Cookies();
  const token = cookie.get("userLoginData");

  useEffect(() => {}, [token]);
  return token ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateComponent;