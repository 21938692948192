export class ApiEndpoints {
  static getBaseUrl() {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    switch (environment) {
      case "production":
        return process.env.REACT_APP_PRODUCTION_API_URL;
      case "staging":
        return process.env.REACT_APP_STAGING_API_URL;
      case "local":
        return process.env.REACT_APP_LOCAL_API_URL;
      default:
        throw new Error(`Invalid environment: ${environment}`);
    }
  }
  static BASE_URL = ApiEndpoints.getBaseUrl();
  static LOGIN = `${ApiEndpoints.BASE_URL}/login`;
  static LEADS = `${ApiEndpoints.BASE_URL}/leads`;
  static VISIT_SUBMIT = `${ApiEndpoints.BASE_URL}/visit`;
  static DOCTORS = `${ApiEndpoints.BASE_URL}/doctors`;
}
