import React, { useEffect, useState } from "react";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SiGoogleads } from "react-icons/si";
import { FaUserDoctor } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Dashboard({ isLogged, setLogged }) {
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [userRoles, setUserRoles] = useState();
  let parseData = "";

  useEffect(() => {
    const cookieData = Cookies.get("userLoginData");
    if (cookieData !== "undefined") {
      const parseData = JSON.parse(cookieData);
      setUserRoles(parseData?.data?.Roles);
    }
  }, []);

  const [isLeadsShown, setIsLeadsShown] = useState(false);
  const [isDoctorsShown, setIsDoctorsShown] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const isSalesRole =
      (userRoles?.length > 0 && userRoles?.includes("Salesrep")) ||
      userRoles?.includes("Sales Manager");
    const isAccountOrBillingManager =
      (userRoles?.length > 0 && userRoles?.includes("Account Manager")) ||
      userRoles?.includes("Billing Manager");

    setIsLeadsShown(isSalesRole);
    setIsDoctorsShown(isAccountOrBillingManager);

    if (isSalesRole) {
      setCount((prevCount) => prevCount + 1);
    }
    if (isAccountOrBillingManager) {
      setCount((prevCount) => prevCount + 1);
    }
  }, [userRoles]);

  console.log(count);

  useEffect(() => {
    if (isLogged) {
      // Show toast
      toast.success("Login successful!", {
        position: "top-center",
        style: {
          // marginTop: "50px",
          color: "#9FB3F2",
        },
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setShowToast(true);
      setLogged(false);
    }
  }, [isLogged]);

  return (
    <>
      <ToastContainer />
      <div className="flex items-center justify-center h-[75vh] mt-[4rem]">
        <div className={`grid gap-4 sm:grid-cols-${count > 1 ? "2" : "1"}`}>
          {(userRoles?.length > 0 && userRoles?.includes("Salesrep")) ||
          userRoles?.includes("Sales Manager") ? (
            <div className="m-4">
              <div
                className="max-w-sm px-[6rem] py-[2rem] text-center bg-white border rounded-lg shadow-lg cursor-pointer p- border-white-200 dark:bg-white-800 dark:border-white-700"
                onClick={() => {
                  navigate("/leads");
                }}
              >
                <div className="flex flex-row items-center justify-center">
                  <SiGoogleads />
                  <p className="ms-2">Leads</p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {(userRoles?.length > 0 && userRoles?.includes("Account Manager")) ||
          userRoles?.includes("Billing Manager") ? (
            <div className="m-4">
              <div
                className="max-w-sm  px-[6rem] py-[2rem] text-center bg-white border rounded-lg shadow-lg cursor-pointer border-white-200 dark:bg-white-800 dark:border-white-700"
                onClick={() => {
                  navigate("/doctors");
                }}
              >
                <div className="flex flex-row items-center justify-center">
                  <FaUserDoctor />
                  <p className="ms-2">Doctors</p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
